body {
  margin: 0;
  padding: 0;
  font-family: 'PingFang SC', 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
html,
body,
#root {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f7fb;
  overflow: auto;
  overflow: overlay;
}

//去掉填充数据的input默认底色
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #4e5767 !important;
}
input:-internal-autofill-selected {
  -webkit-text-fill-color: #4e5767 !important;
}
// 全局重置滚动条样式
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(0, 0, 0, 0.07);
}

// ant design 组件样式覆盖=======================================
//modal 弹窗
.ant-modal {
  .ant-modal-header {
    margin-bottom: 30px;
    .ant-modal-title {
      border-left: 3px solid #4084ff;
      padding-left: 13px;
      line-height: 1;
      color: #1e2b43;
    }
  }
  .ant-modal-content {
    padding: 30px 10px 24px 30px;
    .ant-modal-body {
      padding-right: 20px;
      overflow: auto;
      overflow: overlay;
    }
    .ant-modal-header,
    .ant-modal-footer {
      padding-right: 20px;
    }
  }
  .ant-modal-close {
    top: 28px;
    &:hover {
      background-color: transparent;
    }
  }
  .ant-modal-close-x {
    .iconfont {
      font-size: 14px;
    }
  }
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #ff7d00;
}
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #4e5767;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  border-radius: 4px;
  &.ant-btn-primary {
    background-color: #4084ff;
  }
}
// modal的滚动区域高度自定义
.modal-h-70vh {
  .ant-modal-body {
    height: 70vh;
  }
}
// 按钮样式
.ant-btn:not(:disabled):focus-visible {
  outline: none;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border-color: #b3ceff;
}

// 搜索和重置按钮
.icon-button {
  width: 36px!important;
  height: 36px;
  border-radius: 10px;
  margin: 0 8px;
  &.grey {
    background-color: #f7fbff;
    color: #4e5767;
    border: none;

  }
}

// 重置antTree组件样式
.ant-tree.ant-tree-directory {
  .ant-tree-treenode {
    padding: 8px 0;
    border-radius: 10px;
    overflow: hidden;
    &:hover::before {
      background-color: #fafbfd;
    }
    &::before {
      bottom: 0;
      border-radius: 10px;
    }
    .ant-tree-switcher {
      color: #9db2c5;
    }
    .ant-tree-title {
      color: #4e5767;
    }
  }
  .ant-tree-treenode:hover,
  .ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: #fafbfd;
  }
}

// Form FormItem 文本定宽
.form-label-w-80 {
  .ant-form-item .ant-form-item-label {
    width: 80px;
  }
}

// Textarea 字数统计移到框内
.ant-input-textarea-show-count .ant-input-data-count {
  position: relative;
  top: -23px;
  right: 5px;
}

//tab样式自定义
.tab-container {
  .ant-tabs-top > .ant-tabs-nav::before {
    border: 1px solid #f6fafd;
  }

  .ant-tabs-nav {
    width: 740px;
    height: 40px;
    background: #f6fafd;
    border: 1px solid #f6fafd;
    border-radius: 4px;
    align-items: center;
    margin: 0 30px;
    margin-bottom: 14px;
    .ant-tabs-nav-list {
      align-items: center;
      left: 4px;
      .ant-tabs-tab {
        // width: 118px;
        height: 32px;
        background-color: transparent;
        border: 1px solid transparent;
        color: #72a4fd;
        justify-content: center;
        &.ant-tabs-tab-active {
          background: #ffffff;
          border: 1px solid #ffffff;
          box-shadow: 0px 2px 14px 0px rgba(64, 132, 255, 0.19);
          border-radius: 10px;
          .ant-tabs-tab-btn {
            color: #4084ff;
          }
        }
      }
    }
  }
  &.large-modal {
    .ant-tabs-content {
      padding-bottom: 24px;
      .ant-tabs-tabpane {
        height: 740px;
      }
    }
  }
  &.form-tab{
    .ant-tabs-nav{
      margin: 0 0 14px 0;
    }
  }
}