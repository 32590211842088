// 字号
$fontSizeArr: 8, 10, 12, 14;
@each $item in $fontSizeArr {
  .fs-#{$item} {
    @if $item >=12 {
      font-size: #{$item}px;
    } @else {
      // 小于12号利用scale实现，注意className要加在文字上面，否则box会整体缩小
      font-size: #{$item * 2}px !important;
      transform: scale(0.5);
    }
  }
}
// 外边距
$marginArr: 5, 10, 15, 20, 24, 30, 40;
@each $item in $marginArr {
  .mt-#{$item} {
    margin-top: #{$item}px;
  }
  .mb-#{$item} {
    margin-bottom: #{$item}px;
  }
  .mr-#{$item} {
    margin-right: #{$item}px;
  }
  .ml-#{$item} {
    margin-left: #{$item}px;
  }
}

.h-36 {
  height: 36px;
  border-radius: 10px;
  .ant-select-selector {
    height: 36px !important;
    border-radius: 10px;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 34px !important;
    }
  }
}

.wd-68 {
  width: 68px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-c-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// 带蓝色小圆点标题
.common-title {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-left: 13px;
  &::before {
    content: ' ';
    width: 6px;
    height: 6px;
    background-color: #4084ff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px;
  }
}
